<template>
	<div class="pages-service">
		<div class="top-css1">
			<img class="img-bg img-pc" :src="require('@/assets/image/service_top.png')" />
			<img class="img-bg img-ph" :src="require('@/assets/image/service_top_ph.png')" />
		</div>

		<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:24,offset:0}" class="box-bg">
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:16,offset:4}">
				<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:12,offset:0}"
					class="big-box">
					<div class="b-box">
						<div class="en">COMPANY NEWS</div>
						<div class="cn">公司新闻</div>
						<div class="new-box">
							<div class="img-box">
								<img :src="require('@/assets/image/service_1.png')" />
								<li>德合信息还为湘潭大学定制了学工处官网、后勤处...</li>
								<li>数据分析入门到精通--用Pandas分析全国城市房价</li>
								<li>数据分析入门到精通--用Pandas分析全国城市房价</li>
							</div>
						</div>
					</div>

				</el-col>
				<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:12,offset:0}"
					class="big-box">
					<div class="b-box">
						<div class="new-box">
							<div class="en">Industry NEWS</div>
							<div class="cn">行业新闻</div>
							<div class="img-box">
								<img :src="require('@/assets/image/service_2.png')" />
								<li>数据分析入门到精通--用Pandas分析全国城市房价</li>
								<li>数据分析入门到精通--用Pandas分析全国城市房价</li>
								<li>数据分析入门到精通--用Pandas分析全国城市房价</li>
							</div>
						</div>
					</div>
				</el-col>
			</el-col>
		</el-col>
		<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:16,offset:4}" class="col-pd">
			<div class="module-title" style="width: 100%;padding: 100px 0 50px;">
				<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
				<div style="margin: 0 5px;">软件列表</div>
				<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
			</div>
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}"
				style="display: flex;justify-content: center;">
				<div class="kflc-css">
					<div class="col-css">
						<div class="kflc-box">
							<img :src="require('@/assets/image/service_icon1.png')" />
							<div class="boder-css">
								<div class="title">
									德合超级商城
									<p>一款功能强大的商城小程序，
										让你的电商之路丰富又编辑</p>
								</div>
								<p>适用行业：全行业</p>
								<p>软件版本：微信小程序</p>
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}"
				style="display: flex;justify-content: center;">
				<div class="kflc-css">
					<div class="col-css">
						<div class="kflc-box">
							<img :src="require('@/assets/image/service_icon2.png')" />
							<div class="boder-css">
								<div class="title">
									云建站系统
									<p>无需写代码也能只做精美网站支持可视化操作，快速建站</p>
								</div>
								<p>适用行业：全行业</p>
								<p>软件版本：五合一建站</p>
							</div>
						</div>
					</div>
				</div>
			</el-col>

			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}"
				style="display: flex;justify-content: center;">
				<div class="kflc-css">
					<div class="col-css">
						<div class="kflc-box">
							<img :src="require('@/assets/image/service_icon3.png')" />
							<div class="boder-css">
								<div class="title">
									医疗CRM系
									<p>医院以及门店功效存系统提高管理效率效能</p>
								</div>
								<p>适用行业：全行业</p>
								<p>软件版本：pc 公众号</p>
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}"
				style="display: flex;justify-content: center;">
				<div class="kflc-css">
					<div class="col-css">
						<div class="kflc-box">
							<img :src="require('@/assets/image/service_icon4.png')" />
							<div class="boder-css">
								<div class="title">
									德合社区电商
									<p>社区店主分销模式，类似兴盛优选。主打社交电商+分销</p>
								</div>
								<p>适用行业：生鲜、百货</p>
								<p>软件版本：微信小程序</p>
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}"
				style="display: flex;justify-content: center;">
				<div class="kflc-css">
					<div class="col-css">
						<div class="kflc-box">
							<img :src="require('@/assets/image/service_icon5.png')" />
							<div class="boder-css">
								<div class="title">
									德合智慧医疗
									<p>DIY门店小程序，支持多种膜拜切换，支持多个小程序版本</p>
								</div>
								<p>适用行业：全行业</p>
								<p>软件版本：六合一小程</p>
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}"
				style="display: flex;justify-content: center;">
				<div class="kflc-css">
					<div class="col-css">
						<div class="kflc-box">
							<img :src="require('@/assets/image/service_icon6.png')" />
							<div class="boder-css">
								<div class="title">
									智慧德合同城
									<p>类似美团的同城吃喝玩乐精准消费平台，同城020+电商模式</p>
								</div>
								<p>适用行业：平台型</p>
								<p>软件版本：公众号、小程</p>
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}"
				style="display: flex;justify-content: center;">
				<div class="kflc-css">
					<div class="col-css">
						<div class="kflc-box">
							<img :src="require('@/assets/image/service_icon7.png')" />
							<div class="boder-css">
								<div class="title">
									德合智慧餐饮
									<p>聚合点餐工具、外卖、营销与一体的餐饮评语，也支持单店</p>
								</div>
								<p>适用行业：餐饮行业</p>
								<p>软件版本：小程序</p>
							</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}"
				style="display: flex;justify-content: center;">
				<div class="kflc-css">
					<div class="col-css">
						<div class="kflc-box">
							<img :src="require('@/assets/image/service_icon8.png')" />
							<div class="boder-css">
								<div class="title">
									德合智慧课堂
									<p>知识付费类平台，支持视频、课件、直播等多种教学方式</p>
								</div>
								<p>适用行业：全行业</p>
								<p>软件版本：pc、小程序</p>
							</div>
						</div>
					</div>
				</div>
			</el-col>

		</el-col>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		}
	};
</script>

<style lang="scss" scoped>
	.module-title {
		text-align: center;
		font-size: 26px;
		font-weight: bold;
		line-height: 1;
		margin-bottom: 50px;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.kflc-css {
		// border: 1px solid red;
		position: relative;
		width: 85%;
		height: 460px;

		// margin: 30px;

		.col-css {
			height: 460px;
			width: 100%;
			text-align: center;
		}

		.kflc-box {

			img {
				margin-top: 10px;
				height: 100px;
				object-fit: cover;
			}

			.boder-css {
				border: 2px dashed #D8D8D8;
				padding: 0 10px;
				margin-top: 30px;
				padding-bottom: 20px;
				height: 230px;
			}

			.title {
				padding: 20px 0px;
				border-bottom: 2px dashed #D8D8D8;
				font-weight: 700;
				font-size: 20px;
				margin-bottom: 20px;
				height: 110px;

				p {
					margin-top: 20px;
					font-weight: normal;
				}

			}

			p {
				padding: 0 20px;
				text-align: left;
				font-size: 15px;
			}
		}
	}

	.box-bg {
		background-color: #F5F5F5;
		padding: 30px 60px;
	}

	.big-box {
		display: flex;
		justify-content: center;

		.b-box {
			width: 90%;

			.cn {
				font-size: 26px;
				color: #164BA0;
				margin-bottom: 10px;
			}

			.en {
				font-size: 20px;
				text-transform: uppercase;
			}

			.new-box {
				.img-box {
					width: 100%;
					display: flex;
					flex-direction: column;
					background-color: #fff;
					padding-bottom: 10px;

					img {
						max-width: 100%;
						object-fit: cover;
						margin-bottom: 10px;
					}

					li {
						margin-left: 10px;
						font-size: 16px;
					}
				}

			}
		}


	}




	.top-css1 {
		color: #fff;
		position: relative;

		.img-bg {
			width: 100%;
		}
	}
	.img-ph {
		display: none;
	}

	// 移动端css
	@media screen and (max-width: 767px) {
		.col-pd{
			padding: 0 20px;
		}
		.img-pc {
			display: none;
		}
		
		.img-ph {
			display: block;
		}
		
		.kflc-css {
			// border: 1px solid red;
			position: relative;
			width: 100%;
			height: 460px;
		
			// margin: 30px;
		
			.col-css {
				height: 460px;
				width: 100%;
				text-align: center;
			}
		
			.kflc-box {
		
				img {
					margin-top: 10px;
					height: 100px;
					object-fit: cover;
				}
		
				.boder-css {
					border: 2px dashed #D8D8D8;
					padding: 0 10px;
					margin-top: 30px;
					padding-bottom: 20px;
					height: 230px;
				}
		
				.title {
					padding: 20px 0px;
					border-bottom: 2px dashed #D8D8D8;
					font-weight: 700;
					font-size: 20px;
					margin-bottom: 20px;
					height: 110px;
		
					p {
						margin-top: 20px;
						font-weight: normal;
					}
		
				}
		
				p {
					padding: 0 10px;
					text-align: left;
					font-size: 15px;
				}
			}
		}
		.box-bg {
			background-color: #F5F5F5;
			padding: 30px 20px;
		}

		.big-box>.b-box {
			width: 100%;
		}

		.big-box:last-child {
			margin-top: 30px;
		}

		.top-css1 {
			color: #fff;
			position: relative;
			max-height: 530px;

			.img-bg {
				height: 360px;
				object-fit: cover;
			}
		}
	}
</style>
